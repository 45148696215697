import { queryConfig } from "@lib";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      formData: {
        name: "",
        code: "",
        path: "",
        realtime: "",
        mock: "",
        source: 0,
        state: 0,
      },
    };
  },
  computed: {
    ...mapMutations(["tenement"]),
  },
  async created() {
    try {
      let { status, result } = await queryConfig({
        tenementId: this.tenement?.id,
      });
      if (status === "success") {
        console.error(result);
      } else {
        this.$message.error(result?.error_msg);
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async onAdd() { },
    async onEdit() { },
    async onDel() { },
    async onView() { },
    async onSet() { },
  },
};
